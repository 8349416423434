import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import { Section, SectionWrapper, SubTitle, Text } from "../../core/commonExports"

const ProtectAgainstRisks = ({style={}, desktopStyles={}, mobileStyles={}, titleStyles={}}) => {
  return (
    <Section background="rgba(252, 235, 227, 0.4)" style={style} desktopStyles={desktopStyles} mobileStyles={mobileStyles}>
      <SectionWrapper>
        <SubTitle color="#2A2B7F" fontSize="36px" lineHeight="52px" mfontSize="20px" mlineHeight="34px" style={{textTransform: "uppercase", textAlign: "center", ...titleStyles}}>
          Protect your business against 15+ risks at starting just ₹8 per day
        </SubTitle>
        <ThreatsContainer>
          <ThreatItemContainer>
            <ThreatIconContainer>
              <StaticImage 
                src="../../../assets/images/threat-icons-mlp/fire.webp"
                alt="fire-and-flood"
                width={98}
                height={98}
                loading="lazy"
                placeholder="blurred"
              />
            </ThreatIconContainer>
            <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}}>Fire and Flood</Text>
          </ThreatItemContainer>
          <ThreatItemContainer>
            <ThreatIconContainer>
              <StaticImage 
                src="../../../assets/images/threat-icons-mlp/burglary-theft.webp"
                alt="burglary-and-theft"
                width={98}
                height={98}
                loading="lazy"
                placeholder="blurred"
              />
            </ThreatIconContainer>
            <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}}>Burglary and Theft</Text>
          </ThreatItemContainer>
          <ThreatItemContainer>
            <ThreatIconContainer>
              <StaticImage 
                src="../../../assets/images/threat-icons-mlp/riots.webp"
                alt="riots-and-strikes"
                width={98}
                height={98}
                loading="lazy"
                placeholder="blurred"
              />
            </ThreatIconContainer>
            <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}}>Riots and Strikes</Text>
          </ThreatItemContainer>
          <ThreatItemContainer>
            <ThreatIconContainer>
              <StaticImage 
                src="../../../assets/images/threat-icons-mlp/explosions.webp"
                alt="explosions-and-earthquakes"
                width={98}
                height={98}
                loading="lazy"
                placeholder="blurred"
              />
            </ThreatIconContainer>
            <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}}>Explosions and Earthquakes</Text>
          </ThreatItemContainer>
          <ThreatItemContainer>
            <ThreatIconContainer>
              <StaticImage 
                src="../../../assets/images/threat-icons-mlp/lightning.webp"
                alt="and-much-more"
                width={98}
                height={98}
                loading="lazy"
                placeholder="blurred"
              />
            </ThreatIconContainer>
            <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}}>And much more</Text>
          </ThreatItemContainer>
        </ThreatsContainer>
      </SectionWrapper>
    </Section>
  )
}

export default ProtectAgainstRisks

const ThreatsContainer = styled.div`
  width: 100%; 
  display: flex; 
  justify-content: space-between; 
  align-items: start; 
  gap: 3.5rem;
  margin-bottom: 40px;
  margin-top: 80px;
  @media (max-width: 1250px) {
    gap: 1.5rem;
  }
  @media (max-width: 1080px) {
    gap: 1rem;
  }
  @media (max-width: 768px) {
    & > * {
        width: 150px;
    }
    justify-content: center;
    flex-wrap: wrap !important;
    margin-top: 40px;
  }
`

const ThreatItemContainer = styled.div`
  margin: 1rem auto;
  max-width: 175px;
`

const ThreatIconContainer = styled.div`
  width: 98.35px;
  margin: auto;
  @media screen and (max-width: 768px) {
    width: min(72px, 100%);
  }
`